.bg-desktop-online-physio {
  background-image: url("../../assets/OnlinePhysiotherapy/onlinephysiotherapy_hero_image.png");
  height: 100vh;

  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
}

.bg-desktop-online-physio-mobile {
  background-image: url("../../assets/OnlinePhysiotherapy/onlinephysiotherapy_hero_image.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 1rem;
}

.bg-desktop-online-physio-tablet {
  background-image: url("../../assets/OnlinePhysiotherapy/onlinephysiotherapy_hero_image.png");

  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0;
}

.accordion-container {
  margin: 2rem;
  max-width: 700px;
  display: inline-block;
}

.accordian-details {
  margin: 0;
}

.box-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.pink-container {
  background-color: #ff5a66;
  padding: 1rem;
  text-align: center;
  color: #ffffff;
}

.white-container {
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 16px;
  padding: 1rem 0;
  /* width: 1000px; */
  max-width: 90vw;
  display: inline-block;
  color: #3e4462;
}

.white-container-desktop {
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 16px;
  padding: 1rem 0;
  /* width: 1000px; */
  max-width: 60vw;
  display: inline-block;
  color: #3e4462;
}
.pin-point-flex {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.grid-flex {
  width: 380px;
  padding: 1rem;
  display: flex;
}
.grid-flex-tablet {
  width: 45%;
  padding: 1rem;
  display: flex;
}

.second-column {
  background: #ffffff;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-top-left-radius: 16px;
  padding: 1rem;
}

.price-table {
  padding: 2rem 0;
  width: 1000px;
  max-width: 90vw;
  height: 60rem;
  margin: 0 auto;
  color: #18214d;
}
.price-table-mobile {
  padding: 2rem 0;
  width: 1000px;
  max-width: 90vw;
  height: 100%;
  margin: 0 auto;
  color: #18214d;
}
.compare-price {
  width: 30%;
  float: left;
  font-weight: bold;
  margin-top: 1rem;
  text-align: left;
}
.traditional-price {
  width: 33%;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 16px 0px 0px 16px;
  float: left;
  font-weight: bold;
}
.traditional-price-mobile {
  width: 80%;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 12px;
  margin: 0 auto 48px;
  /* font-weight: bold; */
}
.phyxable-price {
  width: 37%;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 16px;
  float: left;
}
.phyxable-price-mobile {
  width: 80%;

  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  border-radius: 12px;
  margin: 0 auto;
}
.underline-border {
  border-bottom: 1px solid #dfe4ea;
  padding: 0.75rem 0 1.25rem 0;
}

.padding-price {
  padding: 0.75rem 0 1.25rem 0;
}

.header-price {
  padding: 1.25rem 0;
}

.phyxable-price > div > .header-price {
  background-color: #ff5a66;
  padding: 1.25rem 0;
  height: 133px;
  border-radius: 16px 16px 0 0;
  margin-top: -16px;
  align-items: center;
  background-image: url("../../assets/logo/white_logo.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.phyxable-price > div > .pink {
  font-size: 1.5rem;
  line-height: 35px;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.phyxable-price > div > .padding-price {
  padding: 1.5rem;
}

.phyxable-price-mobile > div > .pink {
  font-size: 1.5rem;
  line-height: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.phyxable-price-mobile > div > .padding-price {
  padding: 1.5rem;
}
.phyxable-price-mobile > div > .header-price {
  background-color: #ff5a66;
  padding: 1.25rem 0;
  height: 133px;
  border-radius: 16px 16px 0 0;
  margin-top: -16px;
  align-items: center;
  background-image: url("../../assets/logo/white_logo.svg");
  background-repeat: no-repeat;
  background-position: center;
}
