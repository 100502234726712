.bg-desktop-corporation {
  background-color: aliceblue;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  overflow: auto;
  min-height: 100vh;
}

.bg-mobile-corporation {
  background-color: aliceblue;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  overflow: auto;
  min-height: 100vh;
}

.bg-tablet-corporation {
  background-color: aliceblue;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  overflow: auto;
  min-height: 100vh;
}

.pain-wrap-corporation {
  max-width: 285px;
  padding: 2rem;
}

.book-demo {
  padding: 4rem;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  display: inline-block;
  border-radius: 6px;
  width: 280px;
  min-width: 50%;
}
.book-demo-mobile {
  padding: 2rem;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
  display: inline-block;
  border-radius: 9px;
  width: 100%;
  min-width: 50%;
}
.book-demo-tablet {
  padding: 2rem;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);

  border-radius: 9px;
}

.input-book-demo {
  font-size: 1rem;
  border: none;
  display: inline-block;
  width: 90%;
  background: #eff2f7;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 0;
}

.input-book-demo-tablet {
  font-size: 1rem;
  border: none;
  display: inline-block;
  width: 100%;
  background: #eff2f7;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 0;
}

textarea {
  resize: vertical;
}

.corporation-first {
  padding-top: 150px;
  padding-left: 4rem;
  width: 45%;
  float: left;
}

.corporation-second {
  margin-top: 150px;
  padding: 2rem 0;
  float: right;
  width: 55% ;
}

.pink-corp-container {
  background-image: url("../../assets/Corporations/shoulder_casestudy.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.our-value > ul > li {}

button:disabled,
button[disabled]{
  /* display: none; */
}

@media (max-width: 500px) {
  .book-demo {
    padding: 2rem;
  }
}
