.calendarContainer {
  /* background: #f5f7fa !important; */
  border: none !important;
  width: 100% !important;
}
.color-one {
  background-color: #eff2f7 !important;
}
.color-two {
  background: #f5f7fa !important;
}
.tile {
  padding: 0rem 1rem !important;
  min-height: 60px !important;
  outline: none !important;
}
.tile > abbr {
  font-family: "Proxima-Nova", sans-serif;
  font-weight: 500;
  color: #18182d;
}
.time:disabled {
  background-color: #e4e7ed;
  color: #a7acbd;
  border: 2px solid #e4e7ed;
}
.time:hover {
  background-color: #18214d;
  color: white;
  border: 2px solid #18214d;
}
.time:disabled:hover {
  background-color: #e4e7ed;
  color: #a7acbd;
  border: 2px solid #e4e7ed;
}
.time {
  color: #18214d;
}
