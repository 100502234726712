.bg-desktop-about {
  background-image: url("../../assets/aboutus/Aboutus_heroimage.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-desktop-about-mobile {
  background-image: url("../../assets/aboutus/Aboutus_heroimage.png");
  /* height: 100vh;  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-desktop-about-tablet {
  background-image: url("../../assets/aboutus/Aboutus_heroimage.png");
  /* height: 100vh;  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.divider-about {
  margin: 2rem 0;
  border: 1px solid #ffffff;
}

.sub-title {
  font-size: 20px;
}

.hero-about-us {
  background-image: url("../../assets/aboutus/Aboutus_abstractbackground.svg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-about-us-tablet {
  background-image: url("../../assets/aboutus/Aboutus_abstractbackground.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;
}
.hero-about-text {
  position: absolute;
  color: #18214d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 500px) {
  .hero-about-text {
    width: 300px;
  }

  .hero-about-text > p > br {
    display: none;
  }

  .hero-about-text > h1 > br {
    display: none;
  }
}
