* {
  font-family: Proxima Nova;
}
.cardContainer {
  max-width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
  align-items: center;
  align-self: center;
  margin-left: 100px;
}
.bestValuetitle {
  position: relative;
  font-weight: 900;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.19em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 15px;
  background: #fca727;
  border-radius: 4px;
  bottom: 20px;
}
.leftCardTitle {
  font-size: 25px;
  font-weight: 600;
  color: #18214d;
}
.price {
  margin-top: 10px;
}
.cancelPrice {
  font-size: 16px;
  font-weight: 700;
  color: #18214d;
  text-decoration: line-through;
}
.offerPrice {
  font-size: 18px;
  font-weight: 700;
  color: #3ea65c;
  margin-left: 5px;
}
.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.list {
  font-size: 16px;
  line-height: 20px;
  color: #18214d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-image: url('./assets/favicon-16x16.png');
  width: 80%;
}
.priceCardTitle {
  color: white;
  font-weight: 400;
  font-size: 16px;
}
.bestValueCard {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fca727;
  border-radius: 2px;
  padding: 5px 5px;
  font-size: 8px;
  color: white;
  letter-spacing: 0.19em;
}
.cancelCardPrice {
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-decoration: line-through;
}
.stripeDisclaimer {
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  font-weight: 400;
}
.stripeButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: 25px;
  width: 50%;
  position: relative;
  bottom: 0;
}
.OfferDisclaimer {
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
}
.input {
  margin-top: 20px;
  color: 'red';
}
.nameInput {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  padding: 5px;
  margin-top: 20px;
  width: 48%;
}
.rowLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media all and (max-width: 968px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    padding: 120px 5%;
    width: 100%;
  }
  .section1Container {
    display: flex;
    flex-direction: column;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
  }
  .section1ImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .section1Heading {
    font-size: 35px;
    color: #18214d;
    font-weight: 900;
    line-height: 40px;
  }
  .section1Para {
    font-size: 18px;
    color: #3e4462;
    font-weight: 400;
    margin-top: 10px;
  }
  .section1Button {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section1Image {
    width: 80%;
  }
  .section1Logos {
    width: 100%;
  }
  /* Section 2 */
  .section2Container {
    display: flex;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    align-self: center;
    }
  .InfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .priceCard {
    background-color: #18214d;
    border-radius: 8px;
    box-shadow: inset 0px 1px 6px #080e2b;
    margin-top: 10px;
    width: 100%;
    padding: 10px 5px 10px 10px;
    position: relative;
  }
  .rightCardTitle {
    color: #18214d;
    font-size: 24px;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .stripeLogo {
    width: 50%;
  }
  .stripeCardContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    margin-top: 50px;
    width: 100%;
  }
  .StripeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .backArrow {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    align-items: center;
    cursor: pointer;
  }
  .stripeLogo {
    width: 30%;
  }
  .priceCard {
    background-color: #18214d;
    border-radius: 8px;
    box-shadow: inset 0px 1px 6px #080e2b;
    margin-top: 20px;
    padding: 10px 5px 10px 10px;
    position: relative;
  }
  .stripeForm {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #41455d;
    font-weight: 600;
  }
  .input {
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .stripeInput {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .stripeInput::placeholder {
    color: 'grey';
  }
  .input::placeholder {
    color: #41455d;
  }
  .stripeColumnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .stripeColumn {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  /* Section 3 */
  .section3Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 100px; */
  }
  .section3Title {
    font-weight: 900;
    font-size: 34px;
    color: #18214d;
    text-align: center;
    margin-bottom: 30px;
  }
  .section3RightImage {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    align-items: center;
  }
  .section3LeftImage {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .section3LeftTextContainer {
    width: 100%;
  }
  .section3RightTextContainer {
    width: 100%;
  }
  .section3RightImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .section3LeftImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .section3LeftStepTitle {
    font-size: 16px;
    color: #18214d;
    font-weight: 600;
  }
  .section3RightStepTitle {
    font-size: 16px;
    color: #18214d;
    font-weight: 600;
  }
  .section3LeftStepPara {
    font-size: 18px;
    color: #18214d;
    font-weight: 400;
    margin-top: 10px;
    line-height: 25px;
  }
  .section3RightStepPara {
    font-size: 18px;
    color: #18214d;
    font-weight: 400;
    margin-top: 10px;
    line-height: 25px;
  }
}

@media all and (min-width: 769px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    padding: 150px 10%;
    width: 100%;
  }

  /* Section 1 */
  .section1Container {
    display: flex;
    flex-direction: row;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .section1ImageContainer {
    display: flex;
    flex-direction: column;
    flex: 1.1;
    align-items: center;
  }
  .section1Heading {
    font-size: 55px;
    color: #18214d;
    font-weight: 900;
    line-height: 60px;
  }
  .section1Para {
    font-size: 18px;
    color: #3e4462;
    font-weight: 400;
    margin-top: 10px;
  }
  .section1Button {
    margin-top: 15px;
  }
  .section1Image {
    width: 80%;
  }
  .section1Logos {
    width: 70%;
  }

  /* Section 2 */
  .section2Container {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    width: 100%;
    justify-content: center;
    align-self: center;
  }
  .InfoContainer {
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: center;
  }
  .stripeCardContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    align-items: center;
  }
  .backArrow {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    margin-left: 20px;
    align-items: center;
    cursor: pointer;
  }
  .rightCardTitle {
    color: #18214d;
    font-size: 38px;
    font-weight: 900;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
  .stripeLogo {
    width: 30%;
  }
  .priceCard {
    background-color: #18214d;
    border-radius: 8px;
    box-shadow: inset 0px 1px 6px #080e2b;
    margin-top: 20px;
    width: 60%;
    padding: 10px 5px 10px 10px;
    position: relative;
  }
  .StripeContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  .stripeForm {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #41455d;
    font-weight: 600;
  }
  .input {
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .input::placeholder {
    color: #41455d;
  }
  .stripeInput {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .stripeInput::placeholder {
    color: 'grey';
  }
  .stripeColumnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .stripeColumn {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  /* Section 3 */
  .section3Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section3Title {
    font-weight: 900;
    font-size: 55px;
    color: #18214d;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .section3RightImage {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-bottom: 50px;
  }
  .section3LeftImage {
    display: flex;
    flex-direction: row-reverse;
    width: 80%;
    margin-bottom: 50px;
  }
  .section3LeftTextContainer {
    width: 60%;
  }
  .section3RightTextContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .section3RightImageContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .section3LeftImageContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .section3LeftStepTitle {
    font-size: 16px;
    color: #18214d;
    font-weight: 600;
  }
  .section3RightStepTitle {
    font-size: 16px;
    color: #18214d;
    font-weight: 600;
    text-align: end;
  }
  .section3LeftStepPara {
    font-size: 18px;
    color: #18214d;
    font-weight: 400;
    margin-top: 10px;
    line-height: 25px;
  }
  .section3RightStepPara {
    font-size: 18px;
    color: #18214d;
    font-weight: 400;
    margin-top: 10px;
    line-height: 25px;
    text-align: end;
  }
}
