.bg-desktop-practitioner {
  background-image: url("../../assets/Practitioners/Practitioners_Hero_Image.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-desktop-practitioner-mobile {
  background-image: url("../../assets/Practitioners/Practitioners_Hero_Image.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}
