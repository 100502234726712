@media all and (max-width: 768px) {
  .container {
    margin-top: 100px;
    width: 100%;
  }
  .headercontainer {
    width: 100%;
    padding: 0px 8%;
    display: flex;
    flex-direction: column;
  }
  .heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .headerText {
    font-size: 28px;
    line-height: 35px;
    color: #18214d;
    font-weight: 900;
    display: flex;
    flex-direction: column;
  }
  .headPara {
    margin-top: 10px;
    font-size: 18px;
    color: #3e4462;
    font-weight: 400;
  }
  .subHeading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid#DFE4EA;
    font-size: 18px;
    color: #3e4462;
    font-weight: 400;
    padding-top: 20px;
  }
  .subHeading ul {
    margin-left: 20px;
    /* width: 70%; */
  }
  .imageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 8%;
  }
  .image {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .image img {
    width: 70%;
    height: 250px;
  }
  .imageDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    justify-content: center;
    margin-top: 30px;
  }
  .imageHeading {
    font-size: 25px;
    color: #18214d;
    font-weight: 900;
    width: 100%;
    line-height: 30px;
  }
  .imageName {
    font-size: 20px;
    font-weight: 900;
    color: #18214d;
    line-height: 25px;
    margin-top: 30px;
  }
  .imageName span {
    font-weight: 400;
    font-size: 16px;
  }
  .grayContainer {
    width: 100%;
    background-color: #eff2f6;
    padding-top: 50px;
  }
  .graphContainer {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .graphHeading {
    font-size: 25px;
    color: #18214d;
    font-weight: 900;
    text-align: center;
    line-height: 30px;
  }
  .graphSubHead {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #3e4462;
    margin-top: 20px;
  }
  .graphSubHead span {
    width: 80%;
  }
  .graph {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .graph img {
    width: 100%;
  }
  .footer {
    font-size: 20px;
    color: #18214d;
    font-weight: 900;
    width: 100%;
    padding: 0px 10%;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  .footer span {
    font-size: 18px;
    font-weight: 400;
    color: #3e4462;
    width: 80%;
  }
  .disclaimer {
    margin-top: 40px;
    font-size: 10px;
    font-weight: 400;
  }
}

@media all and (min-width: 769px) {
  .container {
    margin-top: 160px;
    width: 100%;
  }
  .headercontainer {
    width: 100%;
    padding: 50px 10%;
    display: flex;
    flex-direction: row;
  }
  .heading {
    width: 55%;
    display: flex;
    flex-direction: column;
  }
  .headPara {
    margin-top: 10px;
    font-size: 18px;
    color: #3e4462;
    width: 70%;
    font-weight: 400;
  }
  .subHeading {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid#DFE4EA;
    font-size: 18px;
    color: #3e4462;
    font-weight: 400;
  }
  .subHeading ul {
    margin-left: 30px;
    width: 70%;
  }
  .headerText {
    font-size: 50px;
    color: #18214d;
    line-height: 55px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
  }
  .headerText span {
    width: 70%;
  }
  .imageContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 70px 10%;
  }
  .image {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-end;
  }
  .image img {
    border-radius: 10px;
  }
  .imageDetails {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    justify-content: center;
    line-height: 40px;
    position: relative;
  }
  .imageHeading {
    font-size: 30px;
    color: #18214d;
    font-weight: 900;
    width: 60%;
  }
  .imageName {
    font-size: 20px;
    font-weight: 900;
    color: #18214d;
    justify-self: flex-end;
    position: absolute;
    bottom: 0;
    line-height: 30px;
  }
  .imageName span {
    font-weight: 400;
    font-size: 16px;
  }
  .grayContainer {
    width: 100%;
    background-color: #eff2f6;
  }
  .graphContainer {
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .graphHeading {
    font-size: 50px;
    color: #18214d;
    font-weight: 900;
    text-align: center;
  }
  .graphSubHead {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #3e4462;
  }
  .graphSubHead span {
    width: 45%;
  }
  .graph {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
  }
  .footer {
    font-size: 20px;
    color: #18214d;
    font-weight: 900;
    width: 100%;
    padding: 0px 10%;
    display: flex;
    flex-direction: column;
  }
  .footer span {
    font-size: 18px;
    font-weight: 400;
    color: #3e4462;
    width: 50%;
  }
  .disclaimer {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 400;
  }
}
