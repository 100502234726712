.home-container {
  padding-top: 150px;
  width: 40rem;
  padding-left: 4rem;
  max-width: 80vw;
  justify-items: center;
  align-items: center;
}

.home-container-mobile {
  padding-top: 150px;
}

.home-container-desktop {
  padding-top: 200px;

  padding-left: 4rem;

  justify-items: center;
  align-items: center;

  width: 60rem;
  padding-left: 4rem;
  max-width: 80vw;
}
.home-container-tablet {
  padding-top: 80px;
  /* width: 30rem; */
  padding-left: 4rem;
  /* max-width: 80vw; */
  justify-items: center;
  align-items: center;
}

.bg-desktop {
  background-image: url("../../assets/home/Landingpage_Hero_Background.png");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-tablet {
  background-image: url("../../assets/home/Landingpage_Hero_Background.png");
  min-height: 50vh;
  /* width: 100%; */
  /* width: 100vw; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-desktop-mobile {
  background-image: url("../../assets/home/Landingpage_Hero_Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.divider-home {
  margin: 1rem 0;
  border: 1px solid #ffffff;
}
.divider-home-tablet {
  margin: 1rem 0;
  border: 1px solid #ffffff;
  width: 100%;
}

.pain-container {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem;
  justify-content: center;
  /* align-items: center; */
}

.pain-wrap {
  max-width: 220px;
}

.responsive-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4rem;
  align-items: center;
  justify-content: center;
}

.left {
  text-align: left;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  max-width: 40vw;
  min-width: 18rem;
}

.right {
  text-align: left;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  max-width: 40vw;
  min-width: 18rem;
}

img .left .right {
  align-items: center;
  justify-content: center;
  padding: 0;
}

.our-value {
  background-color: #ff5a66;
  color: #ffffff;
  padding: 0.5rem 4rem;
  font-size: 1.25rem;
}
.our-value-tablet {
  background-color: #ff5a66;
  color: #ffffff;
  padding: 1rem 0;
}

.nav-our-value {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto auto auto;
}

.nav-our-value li {
  display: inline-block;
  padding: 20px;
}

.patient-img {
  width: 300px;
  height: 400px;
}

.patient-img-mobile {
  object-fit: cover;
  height: 357px;
  width: 100%;
  border-radius: 8px;
}

.patient-img-tablet {
  object-fit: cover;
  /* height: 357px; */
  width: 90%;
  border-radius: 8px;
}

.PatientAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.PatientAnimation-enter-active {
  opacity: 0;
  transform: translateY(-1);
  transition: opacity 350ms, transform 350ms;
}
.PatientAnimation-exit {
  opacity: 1;
}
.PatientAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.next-button {
  border-radius: 50%;
  border: 0;
  outline: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  background: #eff2f6;
  color: #18214d;
}

.next-button:hover {
  filter: brightness(0.8);
}

.video-player {
  width: 999px;
  max-width: 90vw;
  height: auto;
  margin: auto;
}

.jw-reset {
  border-radius: 20px;
}

.img-200 {
  height: 200px;
  max-width: 100%;
}
.img-150 {
  height: 150px;
  max-width: 100%;
}
.img-100 {
  height: 75px;
}
.img-100-actual {
  height: 100px;
}

.padding-vector {
  padding-top: 50px;
}

.landing-button {
  padding: 1rem 0;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto auto;
  width: max-content;
}

.main-font {
  font-size: 3rem;
  line-height: 3.25rem;
  margin-bottom: 0;
}

.hero-map {
  background-image: url("../../assets/home/map.svg");
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-map-tablet {
  background-image: url("../../assets/home/map.svg");
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text {
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-menu {
  padding: 4rem;
}

.home-menuPartners {
  display: flex;
  justify-content: start;
}

.pain-container h2 {
  margin-top: -1rem;
}

@media (min-width: 1000px) {
  .hero-text {
    left: 25%;
  }
}

@media (max-width: 1025px) {
  .padding-vector1 {
    transform: rotate(270deg);
  }
  .padding-vector2 {
    transform: rotate(270deg);
  }
}

@media (min-width: 501px) {
  .bg-mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  .home-container {
    padding-top: 100px;
    padding-left: 0;
    justify-items: center;
    align-items: center;
    margin: auto;
  }

  .pain-container {
    grid-template-columns: auto;
    padding: 2rem 1rem;
  }

  .responsive-container {
    grid-template-columns: auto;
    padding: 0 1rem;
  }

  .left,
  .right {
    padding: 2rem;
  }

  .our-value {
    padding: 1rem 2rem;
  }

  .nav-our-value {
    grid-template-columns: auto auto;
  }

  .patient-img {
    width: 75vw;
  }

  .video-player {
    min-width: 0;
    width: 100%;
  }

  .landing-button {
    grid-template-columns: auto;
    grid-gap: 1rem;
    padding: 0;
  }

  .bg-desktop {
    display: none;
  }

  .bg-mobile {
    background-image: url("../../assets/home/Landingpage_Hero_Background.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 120%;
    background-color: #ff5a66;
    color: #ffffff;
  }

  .bg-mobile-mobile {
    height: 100%;
    width: 100%;
    color: #ffffff;
  }

  .main-font {
    font-size: 2.5rem;
    margin-bottom: revert;
  }

  .grid-container {
    grid-gap: 0.5rem;
    padding: 1rem 0;
  }
  .grid-container-mobile {
    grid-gap: 0.5rem;
    padding: 1rem 0;
  }
}
