.container-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  margin-top: 0px;
}

.bg-support {
  /* background-image: url("../../assets/faq/FAQ_hero.svg"); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-support-mobile {
  background-image: url("../../assets/faq/FAQ_hero.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
}

.bg-support-tablet {
  background-image: url("../../assets/faq/FAQ_hero.svg");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
}
.support-main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  justify-content: center;
}

.support-wrap {
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 25vw;
  min-width: 15rem;
}

.red-text {
  color: #ff5a66;
  font-family: 'Proxima Nova';
}

.cards-container {
  display: flex;
  width: 100%;
}

.card {
  display: flex;
  background-color: #eff2f6;
}

.icons {
  color: #18214d;
}

.title {
  color: #18214d;
}

.sub-heading {
  color: #18214d;
}

.placeholder-mobile{
  display: flex;
  flex-direction: row;
  background-color: #eff2f6;
  margin-top: 20px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 10px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  max-width: 95%;
}

.left-mobile{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
 
  align-items: start;
  padding: 17px 0px 0px 16px;
  min-width: 8rem;
  max-width: 6rem;
}

.right-mobile{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-left: 54.39px;
  padding: 24px 21px 23px 0px;
}

.placeholder-desktop{
  display: flex;
  flex-direction: row;
  background-color: #eff2f6;
  margin-top: 20px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 85%;
}

.left-desktop{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* justify-content: start; */
  align-items: start;
  
}

.right-desktop{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

.button-tour {
  background-color: #ff5a66;
  border-radius: 70px;
  width: 120px;
  height: 35px;
  font-size: 11px;
  margin-top: 10px;
  font-weight: 550;
  color:white;
}
.button-tour:hover{
  background-color: white;
  color: #ff5a66;
  border:2px solid #ff5a66;
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 90%;
  color: #18214d;
  font-weight: 700;
  margin-top: 20px;
}

.question-card {
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.question-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.collapse-view {
  padding: 0px 5px;
  font-weight: 400;
}
.readmore {
  text-decoration: underline;
}

@media all and (max-width: 500px) {
  .red-text-media {
    font-size: 20px;
    font-weight: 900;
  }
  .container-support-media {
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .cards-container-media {
    margin-top: 25px;
    flex-direction: column;
  }
  .card-media {
    padding: 0px 25px;
    border-radius: 15px;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 10px;
    margin-bottom: 20px;
  }
  .text-media {
    font-size: 11px;
    margin-left: 1px;
    font-weight: 400;
  }
  .heading-media {
    font-weight: 700;
    font-size: 11px;
  }
  .title-media {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sub-heading-media {
    margin-top: 30px;
    font-size: 21px;
    font-weight: 900;
  }
  .left .text-media {
    font-size: 10px;
  }
  .question-card-media {
    font-size: 13px;
    padding: 2px 5px;
  }
  .back-arrow-media {
    width: 100%;
    margin-left: 5px;
    margin-top: 5px;
  }
}

@media all and (min-width: 501px) and (max-width: 834px) {
  .red-text-media {
    font-size: 30px;
    font-weight: 900;
  }
  .container-support-media {
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .cards-container-media {
    margin-top: 30px;
    flex-direction: column;
  }
  .card-media {
    padding: 0px 25px;
    border-radius: 15px;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
  .text-media {
    font-size: 10px;
    margin-left: 10px;
    font-weight: 400;
  }
  .heading-media {
    font-weight: 700;
    font-size: 17px;
  }
  .title-media {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sub-heading-media {
    margin-top: 40px;
    font-size: 23px;
    font-weight: 900;
  }
  .left .text-media {
    font-size: 10px;
  }
  .question-media {
    width: 100%;
  }
  .question-card-media {
    font-size: 15px;
    padding: 7px 5px;
  }
  .back-arrow-media {
    width: 100%;
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media all and (min-width: 835px) and (max-width: 1025px) {
  .red-text-media {
    font-size: 40px;
    font-weight: 700;
  }
  .container-support-media {
    padding-top: 40px;
    /* padding-left: 5%;
    padding-right: 5%; */
    width: 100%;
  }
  .cards-container-media {
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20%;
    padding-right: 20%;
  }
  .card-media {
    padding: 0px 20px;
    width: 23%;
    height: 130px;
    border-radius: 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-media {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 400;
  }
  .heading-media {
    font-weight: 700;
    font-size: 14px;
  }
  .title-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sub-heading-media {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 700;
  }
  .left .text-media {
    font-size: 11px;
  }
  .question-media {
    width: 50%;
  }
  .question-card-media {
    font-size: 18px;
    padding: 10px 5px;
  }
  .back-arrow-media {
    width: 100%;
    margin-left: 15px;
    margin-top: 15px;
  }
}

@media all and (min-width: 1025px) {
  .red-text-media {
    font-size: 50px;
    font-weight: 700;
  }
  .container-support-media {
    padding-top: 50px;
    padding-left: 25%;
    padding-right: 25%;
  }
  .cards-container-media {
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-media {
    padding: 0px 25px;
    width: 23%;
    height: 160px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-media {
    margin-top: 10px;
    font-size: 9px;
    font-weight: 400;
  }
  .heading-media {
    font-weight: 700;
    font-size: 14px;
  }
  .title-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sub-heading-media {
    margin-top: 60px;
    font-size: 28px;
    font-weight: 700;
  }
  .left .text-media {
    font-size: 16px;
  }
  .question-card-media {
    font-size: 21px;
    padding: 10px 5px;
  }
  .back-arrow-media {
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media all and (min-width: 1200px) {
  .red-text-media {
    font-size: 50px;
    font-weight: 700;
  }
  .container-support-media {
    padding-top: 50px;
    padding-left: 25%;
    padding-right: 25%;
  }
  .cards-container-media {
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
  .card-media {
    padding: 0px 25px;
    width: 23%;
    height: 160px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-media {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }
  .heading-media {
    font-weight: 700;
    font-size: 16px;
  }
  .title-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sub-heading-media {
    margin-top: 60px;
    font-size: 28px;
    font-weight: 700;
  }
  .left .text-media {
    font-size: 16px;
  }
  .question-card-media {
    font-size: 21px;
    padding: 10px 5px;
  }
  .back-arrow-media {
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
  }
}

