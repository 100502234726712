.bg-faq {
  background-image: url("../../assets/faq/FAQ_hero.svg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-faq-mobile {
  background-image: url("../../assets/faq/FAQ_hero.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
}

.bg-faq-tablet {
  background-image: url("../../assets/faq/FAQ_hero.svg");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
}
.faq-main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  justify-content: center;
}

.faq-wrap {
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 25vw;
  min-width: 15rem;
}
