@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  /* Make button Unhiglightable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  color: #18214d;
}

.mobileIcon {
  font-size: 40px;
  color: #18214d;
}
/* GENERAL */
* {
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}

/* GRID */
.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.navRow {
  display: flex;

  /* width: 20%; */
}
.flex-middle {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-between; */
}
.row-middle {
  align-items: center;
}
.column {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  /* border-radius: 4px; */
}

.bordRight {
  border-right: 1px solid #eff2f7;
}
.bordLeft {
  border-left: 1px solid #eff2f7;
}
/* Calendar */
.calendar {
  display: block;
  position: relative;
  width: 90%;
  /* background: "#EFF2F7"; */
  background: #fff;
  /* padding: 5px; */

  /* border-right: 1px solid lightgray; */
  height: auto;
  margin: 0 auto;
  border-style: none;
  border-radius: 4px;
}
.calendar .header {
  /* font-weight: 600; */
  /* font-size: 115%; */
  padding: 1.5em 0 0.5em;
  /* border-bottom: 1px solid lightgray; */
}
.icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.icon:hover {
  transform: scale(1.25);
  transition: 0.25s ease-out;
  color: #ff5a66;
}
.icon:first-of-type {
  margin-left: 1em;
}
.icon:last-of-type {
  margin-right: 0;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: gray;
  font-size: 7px;
  font-family: "Roboto", sans-serif;

  /* padding: 0.75em 0; */
  /* border-bottom: 1px solid lightgray; */
}
.calendar .body .cell {
  position: relative;
  height: 3em;
  width: calc(100% / 7);
  /* border-right: 1px solid lightgray; */
  overflow: hidden;
  cursor: pointer;

  transition: 0.25s ease-out;
}

/* .calendar .body .cell:hover {
  background: whitesmoke;
  transition: 0.5s ease-out;
} */
/* .calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1affa0 0%, #cff153 40%);
  border-image-slice: 1;
} */
/* .calendar .body .row {
  border-bottom: 1px solid lightgray;
} */
.calendar .body .row:last-child {
  border-bottom: none;
}
.calendar .body .cell:last-child {
  border-right: none;
}
.calendar .body .cell .number {
  position: absolute;

  font-size: 10px;
  top: 1em;
  right: 0;

  left: 0;
  font-weight: 700;
  text-align: center;
}
/* .calendar .body .cell:hover .number,
.calendar .body .selected .number {
  visibility: hidden;
} */
.calendar .body .disabled {
  color: lightgray;
  pointer-events: none;
}
/* .calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #1affa0;
  opacity: 0;
  font-size: 5em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
} */
/* .calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.2;
  transition: 0.5s ease-in;
} */
.calendar .body .column {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
