.booking-select-button{
    border: 0;
    outline: 0;
    background: #FFFFFF;
    color: #18214D;
    border-radius: 8px;
    width: 100%;
    max-width: 280px;
    height: 45px;
    font-size: 1rem;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%), 0px 4px 6px rgb(0 0 0 / 10%);
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 0px;
    letter-spacing: 0.1px;
    color: #18214D;
    text-align: left;
}

.booking-select-button:hover{
    background: #DFE4EA;
}

.margin-bottom-1rem {
    margin: 16px 8px;
}



.Yukon-booking-button-select{
    border: 0;
    outline: 0;
    background: #FFFFFF;
    color: #18214D;
    border-radius: 8px;
    width: 280px;
    height: 45px;
    font-size: 1rem;
    /* box-shadow: 0px 2px 4px rgb(0 0 0 / 6%), 0px 4px 6px rgb(0 0 0 / 10%); */
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 0px;
    letter-spacing: 0.1px;
    color: #18214D;
    text-align: left;
    cursor: default;
    margin: 0 0.5rem;
}

.countryName{
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 0px;
    letter-spacing: 0.1px;
    color: #18214D;
}

.provinces-width {
    width: 50%;
    max-width: 1000px;
}

@media only screen and (max-width: 600px) {
    .provinces-width {
      width: 100%;
    }
  }

.countires-padding{
    padding-left: 1rem;
}

@media only screen and (max-width: 600px) {
    .countires-padding {
        padding-left: 0rem;
        padding-top: 1rem;
    }

    .countries-margin-bottom{
        margin-bottom: 10px;
    }
}