footer {
  background-color: #000000;
  color: #ffffff;
  line-height: 1.5;
}
footer a {
  text-decoration: none;
  font-weight: bold;
  color: #ffffff;
}
a:hover {
  color: #dd3c52;
}
.ft-title {
  color: #ff5a66;
  font-size: 18px;
}

.ft-main {
  padding: 1.25rem 0;
  display: flex;
  flex-wrap: wrap;
}
.ft-main-item {
  padding: 1.25rem;
  min-width: 12.5rem /*200px*/;
}

.ft-main li {
  margin-top: 0.675rem;
}

.ft-legal {
  padding: 0.9375rem 2.5rem;
}
.ft-legal-list {
  justify-content: center;
  align-items: center;
  border-top: 1px #dfe4ea solid;
  padding-top: 1.25rem;
  display: flex;
  flex-wrap: wrap;
}
.ft-legal-list li {
  margin: 0.625rem;
  white-space: nowrap;
}
.ft-legal-list li:not(:first-child):hover {
  opacity: 0.5;
}

.ft-legal-list li:nth-last-child(4) {
  flex: 1;
}

.ft-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  padding: 0 4rem;
}

.footer-padding {
  padding: 2.5rem 0 0 2.5rem;
}

@media (max-width: 500px) {
  .ft-container {
    grid-template-columns: auto;
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 29.8125rem /*477px*/) {
  .ft-main {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 77.5rem /*1240px*/) {
  .ft-main {
    justify-content: space-evenly;
  }
}
