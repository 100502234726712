.lp-section {
  text-align: center;
}

.lp-section .bg-desktop-online-physiotherapy{
  background-color: aliceblue;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  overflow: auto;
  min-height: 100vh;
}

.lp-section .bg-shoulder-lowback {
  background-image: url("../../assets/OnlinePhysiotherapy/landing-page-bg.png");
  height: 100vh;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
}

.lp-section .white-svg-container{
  background-color: #ffffff;
  width: 90%;
  max-width: 1000px;
  display: inline-block;
  box-shadow: 0px 40px 60px rgba(24, 33, 77, 0.2);
  border-radius: 16px;
  color: #18214D;
}

.lp-section img {
    padding: 2rem 0;
    max-width: 100%;
}

.lp-section .first {
  padding-top: 150px;
  padding-left: 4rem;
  width: 45%;
  float: left;
}

.lp-section .second {
  margin-top: 150px;
  padding: 2rem 0;
  float: right;
  width: 55% ;
}

.lp-section button {
    background-color: #FF5A66;
    color: #ffffff;
    border-radius: 2rem;
    padding: 0.75rem 2rem;
    border: 0;
    outline: 0;
}

.lp-section button:hover {
    background-color: #DD3C52;
}

.lp-section  p {
  line-height: 2rem;
}

.lp-section > h1, h2, h3, h4, h5, h6 {
  color: #18214D;
}

.lp-section li {
  list-style: inherit;
  font-size: 1.15rem;
  line-height: 2rem;
}

.lp-section ul {
  list-style: inherit;
  padding-left: 2rem;
}

.lp-section a {
  color: #3363ff;
}

.lp-section iframe {
  max-width: 100%;
  max-height: 100%;
  //height: -webkit-fill-available;
}

.lp-section strong {
  font-family: "Proxima Nova Bold";
}

.lp-section .pin-point-flex {
  max-width: 1000px;
}

.lp-section .pain-container {
   display: flex;
   flex-wrap: wrap;
   padding: 4rem;
   justify-content: center;
   /* align-items: center; */
}


.lp-section .pain-wrap-corporation {
  max-width: 285px;
  padding: 2rem;
}

.lp-section .responsive-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4rem;
  align-items: center;
  justify-content: center;
}

.lp-section .insurers {
  display: grid;
  grid-gap: 10px;
  max-width: 100%;
  padding: 0 4rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.lp-section input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// Layout
.lp-section .row {
  display:inline-block;
  max-width: 1000px;
  .col {
    flex:1;
    &:last-child {
      margin-left: 1em;
    }
  }
}

/* Accordion styles */
.lp-section .tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 18px 0px rgba(0,0,0,0.3);
}
.lp-section .tab {
  width: 100%;
  color: #18214d;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: white;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken(white, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: #3e4462;
    background: white;
    transition: all .35s;
    text-align: left;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: white;
    cursor: pointer;
    &:hover {
      background: darken(white, 10%);
    }
  }
}

// :checked
.lp-section input:checked {
  + .tab-label {
    background: darken(white, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}

@media (max-width: 800px) {
  .lp-section .first {
    padding-top: 150px;
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
  
  .lp-section .second {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 500px) {
    .pain-container {
        grid-template-columns: auto;
        padding: 2rem 1rem;
    }
    .responsive-container {
        grid-template-columns: auto;
        padding: 0 1rem;
    }
}