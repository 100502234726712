.bg-desktop-blog {
    background-image: url("../../assets/Blog/Blogs_hero_image.png");
    height: 100vh; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-header {
    padding-top: 4rem;
}

#category {
  font-weight: 500;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  margin: 20px;
  width: 1000px;
  max-width: 90vw;
  display: inline-block;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

// MEDIA QUERY
@mixin media($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: 60em) {
      @content;
    }
  }
}

// VARIABLES
$color-header: #0d0d0d;
$color-text: #404040;

$color-btn-text: #3363ff;
$color-btn-background: #e6ecff;

$transition: .2s;


// MAIN CONTENT
.grid {
  margin: auto;
  display: grid;
  width: 90vw;
  grid-gap: 2rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(20rem, 1fr)
  );
  align-items: start;
  
  @include media(small) {
      padding: 2rem 0;
      display: inline-block;
      grid-gap: 1rem;
   }
  
  &__item {
    margin: 2rem 0;
    background-color: #fff;
    border-radius: .4rem;
    overflow: hidden;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, .1);
    transition: $transition;
    
    &:hover {
      transform: translateY(-.5%);
      box-shadow: 0 4rem 8rem rgba(0, 0, 0, .2);
    }
  }
}




.card {
  &__img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
   }
  
  &__content {
    padding: 1rem;
  }
  
  &__header {
    font-size: 3rem;
    font-weight: 500;
    color: $color-header;
    margin-bottom: 1.5rem;
  }
  
  &__text {
    margin-bottom: 0.5rem;
  }
}

.no-decoration:hover {
  text-decoration: none;
}

.category:hover {
  filter: brightness(0.5);
}

.uk-section {
  display: inline-block;
  width: 850px;
  max-width: 95%;
  padding: 2rem 1rem;
  color: #3C434E9E;
  text-align: left;
}
.uk-section-LP {
  display: flex;
  width: 100%;
  max-width: 95%;
  padding: 2rem 1rem;
  color: #3C434E9E;
  text-align: center;
}

.uk-section  p {
  font-size: 1.15rem;
  line-height: 2rem;
}

.uk-section > h1, h2, h3, h4, h5, h6 {
  color: #18214D;
}

.uk-section li {
  list-style: inherit;
  font-size: 1.15rem;
  line-height: 2rem;
}

.uk-section ul {
  list-style: inherit;
  padding-left: 2rem;
}

.uk-section a {
  color: #3363ff;
}

.uk-section iframe {
  width: 100%;
  height: -webkit-fill-available;
}

.uk-section strong {
  font-family: "Proxima Nova Bold";
}

.uk-section button {
  background-color: #FF5A66;
  color: #ffffff;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  border: 0;
  outline: 0;
}
.uk-section-LP button {
  background-color: #FF5A66;
  color: #ffffff;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  border: 0;
  outline: 0;
}

.uk-section button:hover {
  background-color: #DD3C52;
}