* {
  font-family: 'Proxima Nova';
  color: black;
}
.heading {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.referralCodeContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referralCode {
  border: 1px solid #ff5a66;
  box-sizing: border-box;
  padding: 5px 50px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
.referralCodeCopy {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}
.referralCodeCopied {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #3ea65c;
  font-weight: 900;
}
.emailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.emailText {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.input {
  padding: 0px 10px;
  width: 100%;
  border: none;
}
.submitEmailButton {
  background-color: #18214d;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 10px;
  letter-spacing: 0.15em;
}
.getStartedButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media all and (max-width: 768px) {
  .popup_container {
    display: flex;
    flex-direction: column;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    box-shadow: 0px 3px 29px rgba(138, 149, 158, 0.2);
    border-radius: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    padding: 0px 10%;
    line-height: 18px;
    margin-top: 10px;
    text-align: center;
  }
  .emailBox {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #41455d;
    color: #41455d;
    width: 90%;
    padding: 7px 0px;
    padding-right: 10px;
    border-radius: 10px;
  }
}

@media all and (min-width: 769px) {
  .popup_container {
    display: flex;
    flex-direction: column;
    width: 900px;
    box-shadow: 0px 3px 29px rgba(138, 149, 158, 0.2);
    border-radius: 10px;
  }
  .content {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding: 0px 15%;
    line-height: 18px;
    margin-top: 10px;
    text-align: center;
  }
  .emailBox {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #41455d;
    color: #41455d;
    width: 70%;
    padding: 7px 0px;
    padding-right: 10px;
    border-radius: 10px;
  }
}
