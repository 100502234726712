.bg-desktop-corporate-wellness {
  background-image: url("../../assets/corporate-wellness/bg_corporate_wellness.svg");
  min-height: 100vh;
  background-position: top right;
  background-repeat: no-repeat;
}
.insurers {
  display: grid;
  grid-gap: 10px;
  max-width: 100%;
  padding: 0 4rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.insurers img {
  padding: 2rem 0;
  max-width: 100%;
}

.input__signup {
  padding: 8px;
  margin: 8px 0;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #A7BAC5;
}

.sign_me_up_button {
  width: 100%;
  background: #FF5A66;
  border-radius: 20px;
  color: #fff;
  padding: 8px 0;
}

.pink-divider {
  height: 8px;
  width: 100%;
  background: rgba(241,89,103,0.67);
  margin: 16px 0;
}