.more__actions:first-child:hover {
    background: #18214D;
    color: #fff;
}

.more__actions:last-child:hover {
    background: #FF5A66;
    color: #fff;
}

.more__actions:first-child {
    color: #18214D;
}

.more__actions:last-child {
    color: #FF5A66;
}