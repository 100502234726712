.bg-profile {
  background-color: #eff2f6;
}

.profile-container {
  padding: 4rem;
  width: 100%;
  max-width: 758px;
  padding: 1rem;
}

.social-link {
  padding: 0.25rem;
}

.social-link:hover {
  opacity: 0.5;
}

.virtual {
  background: #1239ff;
  font-size: 0.75rem;
  color: #ffffff;
  width: max-content;
  padding: 0.5rem;
  border-radius: 20px;
}

.profile-practitioner {
  justify-content: left;
  width: 156px;
  height: 156px;
  border-radius: 100%;
  background: #ff5a66 no-repeat center;
  background-size: cover;
  object-fit: contain;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  margin: auto;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0 8px;
  border-radius: 5px;
  input {
    border: none;
    padding: 4px 0 0 0;
    &:focus {
      outline: transparent;
    }
  }
}
.Profile-About {
  width: 50%;
}

#tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}
.div-padder {
  padding: 4rem;
}
.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #ff5a66;
  .tag-title {
    margin-top: 3px;
  }
}

@media (max-width: 501px) {
  .avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .profile-cAlign {
      text-align: center;
    }
    .info-container {
      text-align: center;
      padding: 0;
      margin: 0 auto;
    }
  }
  .div-padder {
    padding: 2rem 0;
  }
  .backBtn {
    padding-left: 3rem;
  }
  .virtual {
    background: #1239ff;
    font-size: 0.75rem;
    color: #ffffff;
    width: max-content;
    padding: 0.5rem;
    border-radius: 20px;
  }
  #tags {
    justify-content: center;
  }
  .Profile-About {
    width: 80%;
  }
}
