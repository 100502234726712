.padding-privacy {
    padding: 4rem 6rem;
    text-align: left;
}

.privacy-background {
    padding-left: 30%;
    text-align: left;
    padding-right: 5%;
}

@media (max-width: 500px) {
    .padding-privacy {
        padding: 0 2rem;
        text-align: left;
    }
    
    .privacy-background {
        padding: 0 2rem;
        text-align: left;
    }

    .terms{
        width: 6rem;
    }
  }