@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/ProximaNova.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/ProximaNovaBold.woff") format("woff");
}

@font-face {
  font-family: "Inter Regular";
  src: local("Inter"), url("./fonts/Inter-Regular-slnt=0.ttf") format("ttf");
}

@font-face {
  font-family: "Inter Bold";
  src: local("Inter"), url("./fonts/Inter-Bold-slnt=0.ttf") format("ttf");
}

@font-face {
  font-family: "Inter Thin";
  src: local("Inter"), url("./fonts/Inter-ExtraLight-slnt=0.ttf") format("ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body,
button,
input,
textarea {
  font-family: "Proxima Nova";
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proxima Nova Bold";
}

h1 {
  line-height: 2.5rem;
}

.ProximaNova {
  font-family: "Proxima Nova", sans-serif;
}
.interRegular {
  font-family: "Inter Regular", sans-serif;
}

.interThin {
  font-family: "Inter Thin", sans-serif;
}
.roboto {
  font-family: "Roboto", sans-serif;
}

.interBold {
  font-family: "Inter Bold", sans-serif;
}

button:hover {
  cursor: pointer;
}

.container {
  flex: 1; /* same as flex-grow: 1; */
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.Content {
  padding: 5rem 0;
  text-align: center;
}

.App .Content {
  padding: 5rem 0;
  text-align: center;
}

ul {
  list-style: none;
  padding-left: 0;
}

.grid-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto auto;
}

.grid-items {
  align-items: center;
  justify-items: center;
  margin: auto;
}

.padding-2rem {
  padding: 2rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-6rem {
  padding-top: 6rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.max-100 {
  max-width: 100%;
}
.max-90 {
  max-width: 90%;
}
.fw-600 {
  font-weight: 600;
}

.button {
  border: 0;
  outline: 0;
  background: #ff5a66;
  color: #ffffff;
  border-radius: 50px;
  width: 250px;
  height: 45px;
  font-size: 1rem;
}

.button:hover {
  background: #dd3c52;
  color: #ffffff;
}

.margin-auto {
  margin: auto;
}

.bold {
  font-weight: 600;
}

.pink {
  color: #ff5a66;
}

.white {
  color: #ffffff;
}

.black {
  color: #000000;
}

.dark {
  color: #18214d;
}

.regular {
  color: #3e4462;
}

.gray {
  color: #9b9b9b;
}

.center {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.input-search {
  width: 90%;
  background: #eff2f6;
  border-radius: 10px;
  border: none;
  padding: 0.75rem;
  margin-top: 0.5rem;
  display: -ms-flexbox; /* IE10 */
  display: flex;
}
.input-search-mobile {
  width: 100%;
  background: #eff2f6;
  border-radius: 10px;
  border: none;
  padding: 0.75rem;
  margin-top: 0.5rem;
  display: -ms-flexbox; /* IE10 */
  display: flex;
}

.input-field {
  padding-left: 0.5rem;
  color: #18214d;
  width: 100%;
  background: #eff2f6;
  border: none;
}

.input-field:focus {
  outline: none;
}

@media only screen and (max-width: 29.8125rem /*477px*/) {
  .grid-container {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 1000px) {
  .button {
    border: 1px solid #ffffff;
  }
}
