.search-container {
  padding: 4rem;
}

.avatar-container {
  width: 100%;
  max-width: 758px;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
  margin: 1rem 0;
  background: #eff2f6;
}

.avatar-container-mobile {
  width: 100%;

  padding: 1rem;
  /* display: flex; */
  border: 0.3px solid #000000;
  border-radius: 30px;
  margin: 1rem 0;
  background: transparent;
}

.info-container {
  text-align: left;
  padding-left: 2rem;
  width: 75%;
  height: 100%;
  max-width: 350px;
}

.avatar-practitioner {
  justify-content: left;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #ff5a66 no-repeat center;
  background-size: cover;
  object-fit: contain;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  margin: auto;
}

.prac-map-container::after {
  content: "";
  display: table;
  clear: both;
}

.prac-details {
  float: left;
  padding: 0 4rem;
}

.map-container {
  height: 100vh;
  padding: 4rem 0;
  float: left;
  width: 25rem;
}

.button-container {
  float: right;
  width: 370px;
  height: 100%;
}

a {
  text-decoration: none;
  color: #000000;
}

.w3-third {
  width: 90%;
  float: left;
  padding: 0.5rem 0;
}

.book-button {
  border: 0;
  outline: 0;
  margin: 0.2rem;
  background: #ff5a66;
  color: #ffffff;
  border-radius: 28px;
  width: 112px;
  height: 30px;
  font-size: 0.75rem;
}

.profile-button {
  border: 0;
  outline: 0;
  margin: 0.2rem;
  background: #ffffff;
  color: #ff5a66;
  border: 0.1rem solid #ff5a66;
  border-radius: 28px;
  width: 112px;
  height: 30px;
  font-size: 0.75rem;
}

.book-button:hover {
  background: #ffffff;
  color: #ff5a66;
  border: 0.1rem solid #ff5a66;
}

.profile-button:hover {
  background: #ff5a66;
  color: #ffffff;
}

@media (min-width: 601px) {
  .w3-third {
    width: 33.33333%;
    min-width: fit-content;
  }
  .w3-third > button {
    margin: 1.75rem 0;
  }
}

@media(min-width:600px) and (max-width:900px){
  .w3-third {
    width: 50%;
    min-width: fit-content;
  }
}

@media (max-width: 800px) {
  .map-container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .search-container {
    padding: 4rem 2rem;
  }
}
