.Header {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  max-height: 70px;
  padding: 2rem 0;
  width: 100%;
  display: grid;
  grid-template-areas: 'logo  nav';
  z-index: 99;
}
.tabletGrid {
  grid-template-areas: 'logo  nav nav  nav nav';
}

.lang__mobile__dropdown {
  display: none;
}

button:hover {
  box-shadow: none;
  transition: 0.2s;
}

.Header a {
  width: fit-content;
}

.scroll .Header {
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
}

.bars {
  margin-left: 10%;
}

.scroll .Nav a {
  color: #18214d;
}

.Logo {
  grid-area: logo;
  height: 70px;
}
/* .LogoTablet {
  width: 90%;
} */

.Nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  justify-items: center;
}

.Nav a {
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
}

.Nav a:hover {
  color: #ff5a66;
  text-decoration: none;
  transition: 0.2s;
}

.Nav button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  transition: 70ms;
}

.Nav button:active {
  transform: translateY(3px);
}

.Menu {
  display: none;
  grid-area: menu-grid;
  margin: 0 0 0 20px;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
  cursor: pointer;
}

@media (max-width: 700px) {
  .Header {
    grid-template-areas: 'menu-grid';
    padding-left: 0;
  }
  .lang__mobile__dropdown {
    display: inline-block;
  }
  /* .Nav {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    padding: 30px 0 30px;
    background: rgba(40, 44, 47, 0.95);
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  } */
  .Menu {
    display: inline;
  }
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 5px;
  background-color: #0f2522;
  margin: 5px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.signin-button {
  border: 0;
  outline: 0;
  background: #ff5a66;
  transform: none;
  color: #ffffff;
  height: 45px;
  width: 145px;
  border-radius: 500px;
  font-size: 1rem;
  /* background-image: url('../../assets/logo/right-arrow.svg') ;
  background-position: right 10px bottom 16px;
  background-repeat: no-repeat; */
}

.signin-button:hover {
  background: #dd3c52;
  color: #ffffff;
}

/* Hamburger menu */
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  /* background-color: rgba(0, 0, 0, 0.9); */
  overflow-x: hidden;
  transition: 0.75s;
}

.overlay-content {
  position: relative;
  opacity: 0;
  transition: opacity 1s ease-in;

  /* top: 25%; */
  /* width: 90%;
  text-align: center;
  margin-top: 30px; */
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  /* color: #818181; */
  display: block;
  transition: 0.3s;
  color: #18214d;
}
.overlay-content a {
  transform: translateX(200%);
  transition: transform 1s ease-out;
}

.closebtn {
  color: #18214d;
}
.overlay a:hover,
.overlay a:focus {
  /* color: #f1f1f1;
   */

  color: #ff5a66;
  /* text-decoration: none; */
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 481px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }

  .closebtn:hover,
  .closebtn:focus {
    color: #ff5a66;
    text-decoration: none;
  }
}
