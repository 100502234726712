@media all and (max-width: 768px) {
  .container {
    margin-top: 100px;
    padding: 0px 5%;
  }
  .heading {
    display: flex;
    flex-direction: column;
  }
  .headText {
    width: 100%;
    font-size: 28px;
    font-weight: 900;
    color: #18214d;
  }
  .headSubText {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #18214d;
    display: flex;
    flex-direction: column;
  }
  .image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    cursor: pointer;
  }
  .image img {
    margin-top: 20px;
    width: 350px;
    height: 230px;
  }
  .modalHeading {
    text-align: center;
    font-size: 20px;
    color: #ff5a66;
    font-weight: 900;
  }
  .modalSubHeading {
    text-align: center;
    margin-top: 15px;
    color: #777777;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 25px;
  }
  .modalInput {
    width: 100%;
    background-color: #eff2f7;
    height: 40px;
    padding-left: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .modalButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  .modalButton {
    background-color: #ff5a66;
    color: white;
    padding: 15px 50px;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .navigationButton {
    background-color: #ff5a66;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    padding: 5px 40px;
  }
  .modalContainer {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 3px 29px rgba(138, 149, 158, 0.5);
  }
  .formContainer {
    padding: 20px 20px;
  }
  .close {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
}

@media all and (min-width: 769px) {
  .container {
    margin-top: 160px;
    padding: 0px 7%;
  }
  .heading {
    display: flex;
    flex-direction: row;
  }
  .headText {
    width: 50%;
    font-size: 57px;
    font-weight: 900;
    color: #18214d;
  }
  .headSubText {
    width: 80%;
    font-size: 16px;
    font-weight: 400;
    color: #18214d;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .headSubText span {
    width: 70%;
  }
  .image {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    cursor: pointer;
  }
  .image div {
    width: 20%;
    /* background-color: red; */
  }
  .image div img {
    height: auto;
  }
  .modalContainer {
    border-radius: 20px;
    box-shadow: 0px 3px 29px rgba(138, 149, 158, 0.5);
  }
  .modalHeading {
    text-align: center;
    font-size: 25px;
    color: #ff5a66;
    font-weight: 900;
  }
  .modalSubHeading {
    text-align: center;
    margin-top: 20px;
    color: #777777;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .modalInput {
    width: 100%;
    background-color: #eff2f7;
    height: 50px;
    padding-left: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .modalButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .modalButton {
    background-color: #ff5a66;
    color: white;
    padding: 15px 60px;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .navigationButton {
    background-color: #ff5a66;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    padding: 5px 40px;
  }
  .formContainer {
    padding: 30px 150px;
  }
  .close {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 20px;
    padding-right: 20px;
    cursor: pointer;
  }
}
