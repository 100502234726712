.parent {
  width: 100vw;
  height: 200vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;

  .child-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 30%;
    font-family: "futura-pt";
    margin: auto;
    margin-top: 100px;
    top: 0;

    .title {
      font-size: 30px;
      font-weight: bold;
    }

    .sub-child {
      display: flex;
      flex-direction: row;
      justify: center;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 25px;
      }
      p {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 20px;
        text-align: center;
        color: black;
      }
    }
    .time-stamp {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      h4 {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        font-size: 20px;
        color: black;
      }
    }

    .tiny-info {
      padding: 10px 70px;
      color: #ff5a66;
      text-align: center;
    }
    .action-button {
      background-color: #18214d;
      color: #ffffff;
      border-radius: 25px;
      padding: 10px 60px;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 501px) {
  .parent .child-container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    // background-color: red;
  }
  .tiny-info {
    padding: 10px 30px;
    color: #ff5a66;
    text-align: center;
  }
}
