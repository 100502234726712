.ftSz-1 {
  /* font-size: 8px; */
  font-size: 14px;
}
.ftSz0 {
  /* font-size: 10px; */
  font-size: 14px;
}
.ftSz1 {
  /* font-size: 12px; */
  font-size: 14px;
}
.ftSz4 {
  font-size: 18px;
}
.ftSz7 {
  font-size: 24px;
}
.priColor {
  color: #ff5a66;
}
.secColor1 {
  color: #18214d;
}
.secColor2 {
  color: #3e4462;
}
.secColor3 {
  color: #07122a;
}
.ftSz9 {
  font-size: 28px;
}
.ftSz12 {
  font-size: 34px;
}
.ftSz13 {
  font-size: 36px;
}

.ftSz14 {
  font-size: 38px;
}
.ftSz15 {
  font-size: 40px;
}
.ftSz16 {
  font-size: 42px;
}
.ftSz3 {
  font-size: 16px;
}
.ftSz2 {
  font-size: 14px;
}
.ftSz10 {
  font-size: 30px;
}
.ftSz11 {
  font-size: 32px;
}
.ftWght400 {
  font-weight: 400;
}

.ftWght600 {
  font-weight: 600;
}
.ftWght1 {
  font-weight: 1;
}

.ftSz5 {
  font-size: 20px;
}
.ftSz6 {
  font-size: 22px;
}
.ftWghtREG {
  font-weight: regular;
}

.ltSpREG {
  letter-spacing: -0.4px;
}

.ltSp1 {
  letter-spacing: -0.5px;
}
.ltSp11 {
  letter-spacing: -1.5px;
}
.ltSp8 {
  letter-spacing: -1.2px;
}
.w-50per {
  width: 50%;
}
.w-20per {
  width: 20%;
}
.w-25per {
  width: 25%;
}
.w-65per {
  width: 65%;
}
.w-60per {
  width: 60%;
}
.w-55per {
  width: 55%;
}
.w-75per {
  width: 75%;
}
.w-80per {
  width: 80%;
}
.w-90per {
  width: 90%;
}
.w-95per {
  width: 95%;
}
.w-155vw {
  width: 155vw;
}

.w-70per {
  width: 70%;
}
.w-85per {
  width: 85%;
}
.w-55per {
  width: 55%;
}

.w-100per {
  width: 100%;
}

.p1 {
  padding: 2px;
}
.mtAuto {
  margin-top: auto;
}

.mbAuto {
  margin-bottom: auto;
}
.mlAuto {
  margin-left: auto;
}

.mrAuto {
  margin-right: auto;
}

.mtop-5 {
  margin-top: -28px;
}
.mtop-15 {
  margin-top: -48px;
}
.mtop-25 {
  margin-top: -68px;
}
.mtop-10 {
  margin-top: -38px;
}

.p0 {
  padding: 0;
}
.m0 {
  margin: 0;
}
.mtop-1 {
  margin-top: -20px;
}
.mbottom-1 {
  margin-bottom: -20px;
}
.mbottom-15 {
  margin-bottom: -60px;
}
.mtop-01 {
  margin-top: -2px;
}
.mtop-04 {
  margin-top: -8px;
}
.mtop-05 {
  margin-top: -10px;
}

.mtop10 {
  margin-top: 42px;
}
.ptop100 {
  padding-top: 100px;
}
.ptop150 {
  padding-top: 150px;
}

.pbottom2 {
  padding-bottom: 24px;
}

.mtop2 {
  margin-top: 24px;
}
.mtop7 {
  margin-top: 34px;
}
.mbottom2 {
  margin-bottom: 24px;
}
.mbt4 {
  margin-bottom: 4px;
}

.mbottom10 {
  margin-bottom: 40px;
}
.mbottom14 {
  margin-bottom: 48px;
}
.mtop15 {
  margin-top: 50px;
}
.mbottom15 {
  margin-bottom: 50px;
}
.mtop6 {
  margin-top: 32px;
}
.mtop5 {
  margin-top: 30px;
}
.mright1 {
  margin-right: 10px;
}
.lAlign {
  text-align: left;
}
.rAlign {
  text-align: right;
}
.cAlign {
  text-align: center;
}
.dFlex {
  display: flex;
}
.flexRow {
  flex-direction: row;
}
.jCenter {
  justify-content: center;
}
.jStart {
  justify-content: start;
}
.jSpace {
  justify-content: space-between;
}
.flexWrap {
  flex-wrap: wrap;
}
.flexCol {
  flex-direction: column;
}
.flexAlCenter {
  align-items: center;
}
.flexAlStart {
  align-items: flex-start;
}
.flexAlSelfStart {
  align-self: flex-start;
}

.flexAlSelfCenter {
  align-self: center;
}
.flexAlSelfEnd {
  align-self: flex-end;
}

.ltSpace {
  letter-spacing: -0.198758px;
}
.blackColor {
  color: #000000;
}
.whiteColor {
  color: #fff;
}
.primaryBackground {
  background: #ff5a66;
}
.p6 {
  padding: 24px;
}
.pad0 {
  padding: 0;
}
.p1 {
  padding: 14px;
}
.boxShadowReg {
  background-color: transparent;
  box-shadow: 0px 9px 18px rgba(24, 33, 77, 0.2);
}
.posAbs {
  position: absolute;
}
.topLeft50per {
  top: 50%;
  left: 50%;
}
.tranlateReg {
  transform: translate(-50%, -50%);
}

.w-3rdPer {
  width: calc(100% / 3);
}

.w-4thPer {
  width: calc(100% / 4);
}

.pTwo {
  padding: 10px;
}

.pbottom80 {
  padding-bottom: 80px;
}
.FlexWrap {
  flex-wrap: wrap;
}
.overlayMobile {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.boxShadowBooking {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}
