.sub-on {
  width: 100vw;
  min-height: 100%;
  margin: auto;
  // display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  z-index: 1000;

  .just-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    width: 40%;
    font-family: "futura-pt";
    margin: auto;
    // margin-top: 30px;

    .tiles {
      color: black;
      font-size: 25px;
      font-weight: bold;
      height: 2px;
      text-align: center;
      // padding-bottom: 20px;
    }

    .tiles2 {
      color: black;
      font-size: 17px;
      height: 0px;
      padding-top: 10px;
      text-align: center;
    }

    .complete-img {
      width: 80px;
      height: 80px;
    }

    .dialog-container_2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 50px;

      .mark-icon {
        width: 50px;
        height: 50px;
      }

      .message {
        font-size: 19px;
        padding: 20px;
        font-weight: bold;
      }
    }

    .p1 {
      align-self: flex-start;

      .close-button {
        width: 12px;
        height: 15px;
      }
    }

    .child-1 {
      display: flex;
      flex-direction: row;
      padding: 20px;

      .child-1-1 {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 15px;

        .input-holder {
          input {
            background-color: #eff2f6;
            width: 100%;
            padding: 8px;
          }
        }

        .patient-details {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          background-color: #eff2f6;
          border-radius: 5px;
          margin: 20px 0px;
          padding: 15px;

          button {
            height: 32px;
            margin-right: -12px;
            width: 120px;
            color: #fff;
            border-radius: 25px;
            font-size: 10px;
            font-weight: 600;
            max-width: 90%;
            background: #74d173;
            font-family: "futura";
            cursor: pointer;
            border: none;
            padding: 5px;
          }
        }

        .patient-tag {
          //  display: flex;
          //  flex-direction: row;
          //  justify-content: flex-start;
          //  align-items: flex-start;
          width: 100%;
          background-color: #eff2f6;
          border-radius: 5px;
          margin: 20px 0px;
          padding: 5px;

          p {
            width: 100%;
            font-size: 18px;
            color: #18214d;
          }
        }

        // :nth-child(3){
        //     margin-top: 30px;
        // }

        p {
          margin-top: 10px;
        }

        .picker {
          width: 170px;
          border-radius: 5px;
          height: 40px;
        }

        .date-time-holder {
          display: flex;
          flex-direction: row;
          width: 100%;
          // justify-items: flex-start;
          align-items: flex-start;
          justify-content: space-between;

          .picker {
            width: 140px;
            border-radius: 5px;
            height: 40px;
          }
        }

        .field {
          width: 350px;
          height: 35px;
          border-radius: 5px;
        }

        .rdiv {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 5px 0px;

          p {
            margin-top: -3px;
            margin-left: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 15px;
            font-size: 16px;
          }

          .pass {
            margin-top: -3px;
            margin-left: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 15px;
            font-size: 16px;
            color: #18214d;
            font-weight: bold;
          }

          .rImage {
            width: 20px;
            border-radius: 50px;
          }
        }

        h4 {
          height: 5px;
          font-size: 16px;
          font-weight: bold;
        }

        .action-buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          .google {
            background-color: #5f83b4;
            color: white;
            padding-bottom: 10px;

            img {
              width: 30px;
              border-radius: 30px;
              position: absolute;
              left: 105px;
              margin-bottom: 10px;
              margin-right: 50px;
            }
          }

          button {
            margin: 0px 10px;
            padding: 5px 15px;
            border-radius: 25px;
            width: 70%;
            border-width: 0px;
            // font-weight: bold;
            font-size: 16px;
            padding: 8px;
          }

          :nth-child(1) {
            background-color: #18214d;
            color: white;
          }
        }
      }
    }

    .h2-price {
      height: 5px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .h2-consult {
      margin-top: 15px;
      height: 5px;
      font-weight: bold;
    }

    .completion {
      margin: 20px 10px;
      padding: 5px 15px;
      border-radius: 25px;
      width: 260px;
      border-width: 0px;
      font-weight: bold;
      font-size: 12px;
      background-color: #74d173;
      color: white;
    }

    .info-text {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 40px;
      width: 30vw;
      text-align: center;
      color: #18214d;
      font-size: 16px;
      line-height: 20px;
    }

    .book {
      width: 10px;
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 12px;
      color: #000000;
    }

    .ableButton {
      border: 0px;
      padding: 10px 50px;
      background-color: #2a2e35;
      color: white;
      border-radius: 25px;
      font-size: 14px;
      font-weight: bold;
    }

    .disableButton {
      border: 0px;
      padding: 10px 50px;
      background-color: #757980;
      color: white;
      border-radius: 25px;
      font-size: 14px;
      font-weight: bold;
    }

    button:focus {
      outline: none;
    }

    p {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
[data-tip] {
  text-align: left;
 }

 .form-control{
  border: #817e7e;
  border-radius: 0.25rem;
  border-width: 0.10rem;
  border-style: solid;
  width: '90%';  

 }
 .form-group{

  margin: 0.25rem 0.25rem 0.75rem 0.25rem ;
  display: flex;
  justify-content: center;
 
 }

 .question-mark{
  /* top:  10px; */
  margin: 0px 8px;
  padding: 0px 8px;
  border-radius: 50%;
  border: black 1px solid;
}

// .styleWeb {
//   padding: 20;
//   height: '40%';
//   width: '35%';
//   font-size: '1rem';
//   margin: '5% auto';
//   justify-content: 'center';
//   align-content: 'center';
//   display: 'flex';
//   flex-direction: 'column';
//   font-family: 'ProximaNova'

// }

// .styleMobile {
//   padding: 20;
//   height: '40%';
//   width: '80%';
//   font-size: '1rem';
//   margin: '5% auto';
//   justify-content: 'center';
//   align-content: 'center';
//   display: 'flex';
//   flex-direction: 'column'

// }
